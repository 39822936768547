<template>
  <div>
    <b-overlay :show="showInfo" rounded="sm">
    <b-form
      align="left"
      id="formularioResiduos"
      @submit="modalEnviar"
      @reset="cancelarModal"
    >
      <b-card header="Foto Animal">
        <b-form-row>
          <b-col cols="12" md="4">
            <div class="fotoMascota">
              <img :src="verImagenMascota" alt="foto mascota" />
              <b-form-group>
                <b-form-file
                  id="foto"
                  :state="Boolean(form.foto)"
                  placeholder="Foto mascota"
                  browseText="Buscar"
                  accept=".jpg, .JPEG, .JPG"
                  size="sm"
                  @change="imagenMascota"
                  v-if="!cargadaFoto"
                  :disabled="verDetalles ? true : false"
                ></b-form-file>
              </b-form-group>
            </div>
          </b-col>

          <b-col cols="12" md="8">
            <b-form-row>
              <b-col cols="12" lg="4" v-if="vistaVeterinario">
                <b-form-group
                  id="group-iua"
                  label="ID. Mascota"
                  label-for="iua"
                >
                  <b-form-input
                    id="iua"
                    v-model="form.iua"
                    :required="vistaVeterinario?true:false"
                    :disabled="verDetalles ? true : false"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="4">
                <b-form-group
                  id="group-nombre"
                  label="Nombre mascota"
                  label-for="nombre"
                >
                  <b-form-input
                    id="nombre"
                    v-model="form.nombre"
                    required
                    :disabled="verDetalles ? true : false"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" :lg="vistaVeterinario?4:8">
                <b-form-group
                  id="group-id_tipo_animal"
                  label="Tipo animal"
                  label-for="id_tipo_animal"
                >
                  <b-form-select
                    id="id_tipo_animal"
                    v-model="form.id_tipo_animal"
                    class="mb-3"
                    required
                    :options="mapearTipoAnimal"
                    :disabled="verDetalles ? true : false"
                    @change="cargarRazas"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="4">
                <b-form-group
                  id="group-id_raza"
                  label="Raza"
                  label-for="id_raza"
                >
                  <b-form-select
                    id="id_raza"
                    v-model="form.id_raza"
                    class="mb-3"
                    required
                    :options="mapearTipoRaza"
                    :disabled="verDetalles ? true : tiposRazas.length == 0"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <label for="fecha_nacimiento" class="d-block"
                  >Fecha nacimiento:</label
                >
                <b-form-datepicker
                  id="fechaInicio"
                  v-model="form.fecha_nacimiento"
                  class="mb-2 mr-2"
                  placeholder="YYYY/MM/DD"
                  locale="es"
                  labelNoDateSelected="No hay fecha"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  required
                  labelHelp="Use las flechas del teclado para navegar entre fechas"
                >
                </b-form-datepicker>
              </b-col>

              <b-col cols="12" lg="2">
                <b-form-group id="group-edad" label="Edad" label-for="edad">
                  <b-form-input
                    id="edad"
                    v-model="calcularEdad"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>
      </b-card>

      <br />

      <b-card header="Características físicas">
        <b-form-row>
          <b-col cols="12" md="3">
            <b-form-group id="group-sexo" label="Sexo" label-for="sexo">
              <b-form-select
                id="sexo"
                v-model="form.sexo"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1">Macho</b-form-select-option>
                <b-form-select-option value="2">Hembra</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group id="group-color" label="Color" label-for="color">
              <b-form-input
                id="color"
                v-model="form.color"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-descripcion"
              label="Descripción mascota"
              label-for="descripcion"
            >
              <b-form-input
                id="descripcion"
                v-model="form.descripcion"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-cond_corporal"
              label="Cond. Corporal"
              label-for="cond_corporal"
            >
              <b-form-select
                id="cond_corporal"
                v-model="form.cond_corporal"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1"
                  >Muy Delgado</b-form-select-option
                >
                <b-form-select-option value="2">Delgado</b-form-select-option>
                <b-form-select-option value="3"
                  >Peso Ideal</b-form-select-option
                >
                <b-form-select-option value="4">Sobrepeso</b-form-select-option>
                <b-form-select-option value="5">Obeso</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group id="group-entero" label="Castrado" label-for="entero">
              <b-form-select
                id="entero"
                v-model="form.entero"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="0">Si</b-form-select-option>
                <b-form-select-option value="1">No</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-antec_agresividad"
              label="Antecedentes de agresividad"
              label-for="antec_agresividad"
            >
              <b-form-select
                id="antec_agresividad"
                v-model="form.antec_agresividad"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1">Si</b-form-select-option>
                <b-form-select-option value="0">No</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-destinacion"
              label="Destinación"
              label-for="destinacion"
            >
              <b-form-select
                id="destinacion"
                v-model="form.destinacion"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1">Compañía</b-form-select-option>
                <b-form-select-option value="2">Trabajo</b-form-select-option>
                <b-form-select-option value="3">Otro</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" v-if="form.destinacion == 3">
            <b-form-group
              id="group-dest_otro"
              label="Otra destinación"
              label-for="dest_otro"
            >
              <b-form-input
                id="dest_otro"
                v-model="form.dest_otro"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>

      <br />

      <b-card header="Salud">
        <b-form-row>
          <b-col cols="12" md="4" v-if="form.id_tipo_animal != 3">
            <b-form-group
              id="group-vacuna_antirabica"
              label="Vacuna antirrabica"
              label-for="vacuna_antirabica"
            >
              <b-form-select
                id="vacuna_antirabica"
                v-model="form.vacuna_antirabica"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1">Si</b-form-select-option>
                <b-form-select-option value="0">No</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="form.id_tipo_animal == 3">
            <b-form-group
              id="group-vacuna_encefalitis"
              label="Vacuna encefalítis"
              label-for="vacuna_encefalitis"
            >
              <b-form-select
                id="vacuna_encefalitis"
                v-model="form.vacuna_encefalitis"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1">Si</b-form-select-option>
                <b-form-select-option value="0">No</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="form.id_tipo_animal == 3">
            <b-form-group
              id="group-vacuna_tetano"
              label="Vacuna tetano"
              label-for="vacuna_tetano"
            >
              <b-form-select
                id="vacuna_tetano"
                v-model="form.vacuna_tetano"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1">Si</b-form-select-option>
                <b-form-select-option value="0">No</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-carne_vacunas"
              label="Carné de vacunación"
              label-for="carne_vacunas"
            >
              <b-form-file
                id="carne_vacunas"
                :state="Boolean(form.carne_vacunas)"
                placeholder="Elige un archivo"
                browseText="Buscar"
                accept="application/pdf"
                @change="imagenCarne"
                v-if="!cargadoCarne"
                :disabled="verDetalles ? true : vistaVeterinario ? true : false"
              ></b-form-file>

              <b-button
                block
                variant="primary"
                class="mr-2"
                v-if="cargadoCarne"
                @click="descargarPDF(form.carne_vacunas, 'Carne')"
              >
                Descargar
              </b-button>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-historia_clinica"
              label="Historia clínica"
              label-for="historia_clinica"
            >
              <b-form-file
                id="historia_clinica"
                :state="Boolean(form.historia_clinica)"
                placeholder="Elige un archivo"
                browseText="Buscar"
                accept="application/pdf"
                @change="imagenHC"
                v-if="!cargadoHC"
                :disabled="verDetalles ? true : vistaVeterinario ? true : false"
              ></b-form-file>

              <b-button
                block
                variant="primary"
                class="mr-2"
                v-if="cargadoHC"
                @click="descargarPDF(form.historia_clinica, 'HistoriaClinica')"
              >
                Descargar
              </b-button>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>

      <br />

      <b-card
        bg-variant="light"
        header="Vertimientos"
        header-bg-variant="secondary"
        header-text-variant="white"
      >
        <template #header>
          <b-row>
            <b-col class="text-left">
              <div class="mt-1">Historial Clínico</div>
            </b-col>
            <b-col class="text-right" v-if="vistaVeterinario">
              <b-button
                variant="link"
                size="sm"
                class="color-yellow"
                @click="activarModalAdicionar"
                v-if="!noExsiteAnimal"
              >
                <b-icon icon="plus-circle"></b-icon>
                Adicionar
              </b-button>

              <b-button
                variant="link"
                size="sm"
                class="color-yellow"
                type="submit"
                v-if="noExsiteAnimal"
              >
                <b-icon icon="plus-circle"></b-icon>
                Adicionar
              </b-button>
            </b-col>
          </b-row>
        </template>

        <b-row v-if="atencionesVeterinariasServicio.length > 0">
          <b-col>
            <b-table
              id="atencionesVeterinarias"
              striped
              responsive
              small
              bordered
              :items="atencionesVeterinariasServicio"
              :fields="camposTabla"
              :per-page="perPage"
              :current-page="currentPage"
              :tbody-tr-class="rowClass"
              select-mode="single"
              ref="selectableTable"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(seleccionar)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-if="atencionesVeterinariasServicio.length > 0">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="consultaLicencias"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-card>

      <br /><br />

      <div class="text-right">
        <b-button class="mr-3" v-if="editar" type="submit">
          <b-icon icon="plus-circle"></b-icon>
          Guardar
        </b-button>

        <b-button class="mr-3" v-if="!editar" type="submit">
          <b-icon icon="plus-circle"></b-icon>
          Guardar
        </b-button>

        <b-button type="reset"> Cancelar </b-button>
      </div>
    </b-form>

    <b-modal
      id="ModalAdicionarHistoria"
      v-model="mostrarModalAdicionar"
      hide-footer
      size="lg"
      @hide="cancelarModalAtencion"
    >
      <template #modal-title>Agregar Historia Clínica </template>
      <div class="d-block text-center p-3">
        <FormularioHistoriaClinica
          @resetearAtencion="cancelarModalAtencion"
          :vistaVeterinario="this.vistaVeterinario"
        />
      </div>
    </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import ImagenMascota from "../../../assets/imgCabeceras/fotoAnimalesDefault.jpg";
import { encontrarError } from "../../../contantesErrores";
import FormularioHistoriaClinica from "./FormularioHistoriaClinica.vue";

export default {
  name: "FormularioAgregarAnimal",
  components: {
    FormularioHistoriaClinica,
  },
  props: ["animalSeleccionadoAgregar", "vistaVeterinario"],
  data() {
    return {
      showInfo: false,
      persona: {},
      noExsiteAnimal: true,
      mostrarModalAdicionar: false,
      editar: false,
      cargadaFoto: false,
      cargadoCarne: false,
      cargadoHC: false,
      verDetalles: false,
      guardarAdicionar: false,
      cerrarModalAtencionBoolean: false,
      form: {
        id: null,
        fecha_registro: moment(),
        id_tipo_animal: null,
        id_raza: null,
        iua: null,
        nombre: null,
        fecha_nacimiento: null,
        foto: null,
        sexo: null,
        color: null,
        descripcion: null,
        cond_corporal: null,
        vacuna_antirabica: null,
        vacuna_encefalitis: null,
        vacuna_tetano: null,
        entero: null,
        destinacion: null,
        dest_otro: null,
        antec_agresividad: null,
        carne_vacunas: null,
        historia_clinica: null,
      },
      camposTabla: [
        {
          key: "fecha",
          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY");
          },
        },
        {
          key: "nombre_veterinario",
          label: "Veterinario",
        },
        {
          key: "nombre_persona_cita",
          label: "Persona cita",
        },
        {
          key: "tllc",
          label: "TLLC",
        },
        {
          key: "fr_cardiaca",
          label: "Frec. Cardíaca",
        },
        {
          key: "fr_respiratoria",
          label: "Frec. Respiratoría",
        },
      ],
      perPage: 10,
      currentPage: 1,
    };
  },
  async mounted() {
    console.log("desdeModalAgregar");
    if (this.animalSeleccionadoAgregar.id) {
      this.showInfo=true;
      this.editar = true;
      this.noExsiteAnimal = false;
      await this.traerAnimalById(this.animalSeleccionadoAgregar.id);
      await this.traerAtencionVeterinariaById(this.animalSeleccionadoAgregar.id);
      this.showInfo=false;
    }

    if (!this.vistaVeterinario) {
      try {
        await this.cargarTiposAnimales();
      } catch (e) {
        encontrarError(this, e.data);
      }

      if (localStorage.getItem("persona")) {
        try {
          this.persona = JSON.parse(localStorage.getItem("persona"));
        } catch (e) {
          localStorage.removeItem("persona");
        }
      }
      console.log(this.persona);
    }
  },
  computed: {
    ...mapState("animales", [
      "tiposAnimales",
      "tiposRazas",
      "atencionesVeterinariasServicio",
      "resultCreateAnimal",
      "animalCargado",
    ]),
    ...mapState("animalesPersona", ["resultCreateAnimalPersona"]),

    rows() {
      return this.atencionesVeterinariasServicio.length;
    },

    verImagenMascota() {
      let imagen = ImagenMascota;
      if (this.form.foto) {
        imagen = `data:image/jpg;base64,${this.form.foto}`;
      }
      return imagen;
    },

    mapearTipoAnimal() {
      let mapeadas = this.tiposAnimales.map((item) => {
        let animal = {
          value: item.id,
          text: item.descripcion,
        };
        return animal;
      });
      let first = {
        value: null,
        text: "Seleccione una opción",
      };
      mapeadas.unshift(first);
      return mapeadas;
    },

    mapearTipoRaza() {
      let mapeadas = this.tiposRazas.map((item) => {
        let raza = {
          value: item.id,
          text: item.nombre,
        };
        return raza;
      });
      let first = {
        value: null,
        text: "Seleccione una opción",
      };
      mapeadas.unshift(first);
      return mapeadas;
    },

    calcularEdad() {
      let edad = null;
      if (this.form.fecha_nacimiento) {
        let fechaNacimiento = moment(this.form.fecha_nacimiento);
        let fechaActual = moment();
        edad = fechaActual.diff(fechaNacimiento, "years");
      }
      return edad;
    },
  },
  methods: {
    ...mapActions(["activarLoading"]),
    ...mapActions("animalesPersona", ["crearAnimalPersona"]),
    ...mapActions("animales", [
      "cargarAtencionesVeterinarias",
      "crearAnimal",
      "editarAnimal",
      "cargarAnimalById",
      "cargarTiposAnimales",
      "cargarTiposRazasByTipoAnimal",
      "resetTipoRaza",
      "cargarAtencionesVeterinariasByIdAnimal",
      "seleccionarAnimal",
      "seleccionarAtencionVeterinaria",
      "resetAtencionVeterinaria",
    ]),

    async traerAnimalById(id) {
      try {
        await this.cargarAnimalById(id);
        console.log("esto es el animal cargado", this.animalCargado);
        if (this.animalCargado.length > 0) {
          this.form = this.animalCargado[0];
          this.form.id = id;
          this.cargarRazas(this.form.id_tipo_animal);
          this.cargadaFoto = this.form.foto ? true : false;
          this.cargadoCarne = this.form.carne_vacunas ? true : false;
          this.cargadoHC = this.form.historia_clinica ? true : false;
        }
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    async traerAtencionVeterinariaById(id) {
      try {
        await this.cargarAtencionesVeterinariasByIdAnimal(id);
        console.log(
          "esto es el animal cargado",
          this.atencionesVeterinariasServicio
        );
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    async cargarRazas(id) {
      if (id) {
        try {
          await this.cargarTiposRazasByTipoAnimal(id);
        } catch (e) {
          encontrarError(this, e.data);
        }
      } else {
        await this.resetTipoRaza();
      }
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      return "rowspace";
    },

    onRowSelected(items) {
      let items1 = {...items[0]}
      if (items.length > 0) {
        this.seleccionarAtencionVeterinaria(items1);
        this.activarModalAdicionar();
      } else {
        this.seleccionarAtencionVeterinaria({});
      }
    },

    borraFechaNacimiento() {
      this.form.fecha_nacimiento = null;
    },

    imagenMascota(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo, "foto");
    },

    imagenCarne(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo, "carne");
    },

    imagenHC(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo, "HC");
    },

    crearBase64(file, lugar) {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let archivo = e.target.result;
          let base = archivo.split("base64,");
          let pdf = base[1];

          if (lugar == "foto") {
            this.form.foto = pdf;
          }
          if (lugar == "carne") {
            this.form.carne_vacunas = pdf;
          }
          if (lugar == "HC") {
            this.form.historia_clinica = pdf;
          }
        };
        reader.readAsDataURL(file);
      } else {
        if (lugar == "foto") {
          this.form.foto = null;
        }
        if (lugar == "carne") {
          this.form.carne_vacunas = null;
        }
        if (lugar == "HC") {
          this.form.historia_clinica = null;
        }
      }
    },

    descargarPDF(pdf, nombre) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `pdf_${nombre}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    modalEnviar(event) {
      if (event) {
        console.log("event", event);
        if (event.submitter.className != "btn color-yellow btn-link btn-sm") {
          this.noExsiteAnimal = false;
        }
        event.preventDefault();
      }

      if (this.form.fecha_nacimiento != null) {
        this.$bvModal
          .msgBoxConfirm("Se van a guardar los datos, ¿Desea continuar?.", {
            title: "Por favor confirme",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Continuar",
            cancelTitle: "Cancelar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.enviarFormulario();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.modalMensaje("Por favor seleccione una fecha de nacimiento");
      }
    },

    async enviarFormulario() {
      this.activarLoading(true);
      if(!this.vistaVeterinario){
        this.form.iua = -1
      }
      let datosCrear = {
        animal: this.form,
      };
      try {
        if (this.editar) {
          await this.editarAnimal(datosCrear);
        } else {
          await this.crearAnimal(datosCrear);
        }
        if (this.resultCreateAnimal.data.status == 200) {
          if (!this.vistaVeterinario && this.persona.id_persona) {
            let animalPersona = {
              animalpersona: {
                id_animal: this.resultCreateAnimal.data.data.id,
                id_persona: this.persona.id_persona,
                fecha_registro: moment(),
                registrado_censo: 1,
                id_registro_adopcion: null,
                id_usuario: null,
                fecha_entrega_adop: null,
                estado_fisico_adop: null,
                observaciones_adop: null,
                firma_persona: null,
              },
            };
            await this.crearAnimalPersona(animalPersona);
          }
          this.activarLoading(false);
          this.modalOk();
        }
      } catch (e) {
        this.activarLoading(false);
        encontrarError(this, e.data);
      }
    },

    modalOk() {
      this.$bvModal
        .msgBoxOk("Los Datos fueron guardados correctamente.", {
          title: "Confirmación",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(async () => {
          console.log("creado");
          if (this.noExsiteAnimal) {
            await this.traerAnimalById(this.resultCreateAnimal.data.data.id);
            this.editar = true;
            this.activarModalAdicionar();
            this.noExsiteAnimal = false;
          } else {
            this.cancelarModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelarModal(guardar) {
      console.log("cancelar");
      if (guardar == true) {
        this.$emit("resetear", true);
      } else {
        this.$emit("resetear", false);
      }
      this.editar = false;
    },

    activarModalAdicionar() {
      this.mostrarModalAdicionar = true;
    },

    cancelarModalAtencion() {
      this.cerrarModalAtencion();
      this.resetAtencionVeterinaria();
    },

    async cerrarModalAtencion() {
      this.cerrarModalAtencionBoolean = true;
      this.$bvModal.hide("ModalAdicionarHistoria");

      if (this.resultCreateAnimal.data.data.id) {
        this.traerAtencionVeterinariaById(this.resultCreateAnimal.data.data.id);
      } else if (this.animalSeleccionadoAgregar.id) {
        this.traerAtencionVeterinariaById(this.animalSeleccionadoAgregar.id);
      }
    },

    modalMensaje(mensaje) {
      this.$bvModal
        .msgBoxOk(mensaje, {
          title: "Error",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.fotoMascota {
  overflow: hidden;
  height: 210px;
  position: relative;
}
.fotoMascota img {
  position: relative;
  margin-top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
}
.fotoMascota .form-group {
  position: absolute;
  bottom: 0;
  margin: 0;
}
</style>