<template>
  <div>
    <b-form
      align="left"
      id="formularioResiduos"
      @submit="modalEnviar"
      @reset="cancelarModalAtencion"
    >
      <b-card header="Atención veterinaria">
        <b-form-row>
          <b-col cols="12" md="3">
            <label for="fecha">Fecha:</label>
            <b-form-datepicker
              id="fecha"
              v-model="form.fecha"
              class="mb-2 mr-2"
              placeholder="YY/MM/DD"
              locale="es"
              labelNoDateSelected="No hay fecha"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              labelHelp="Use las flechas del teclado para navegar entre fechas"
              required
              :disabled="verDetalles ? true : false"
            >
            </b-form-datepicker>
          </b-col>

          <b-col cols="12" md="3">
            <label for="hora">Hora:</label>
            <b-form-timepicker
              required
              id="hora"
              v-model="form.hora"
              placeholder="A.M."
              labelCloseButton="Cerrar"
              labelNoTimeSelected="No hay hora"
              locale="es"
              :disabled="verDetalles ? true : false"
            ></b-form-timepicker>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-nombre_persona_cita"
              label="Nombre persona"
              label-for="nombre_persona_cita"
            >
              <b-form-input
                id="nombre_persona_cita"
                v-model="form.nombre_persona_cita"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-nombre_animal"
              label="Nombre animal"
              label-for="nombre_animal"
            >
              <b-form-input
                id="nombre_animal"
                v-model="form.nombre_animal"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>

      <br />

      <b-card header="Salud">
        <b-form-row>
          <b-col cols="12" md="3">
            <b-form-group
              id="group-estado_rep"
              label="Estado reproductivo"
              label-for="estado_rep"
            >
              <b-form-input
                id="estado_rep"
                v-model="form.estado_rep"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-mucosas"
              label="Mucosas"
              label-for="mucosas"
            >
              <b-form-input
                id="mucosas"
                v-model="form.mucosas"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-fr_cardiaca"
              label="Frecuencia cardíaca"
              label-for="fr_cardiaca"
            >
              <b-form-input
                id="fr_cardiaca"
                v-model="form.fr_cardiaca"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-fr_respiratoria"
              label="Frecuencia respiratoria"
              label-for="fr_respiratoria"
            >
              <b-form-input
                id="fr_respiratoria"
                v-model="form.fr_respiratoria"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group id="group-tllc" label="TLLC" label-for="tllc">
              <b-form-input
                id="tllc"
                v-model="form.tllc"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-cond_corporal"
              label="Cond. Corporal"
              label-for="cond_corporal"
            >
              <b-form-select
                id="cond_corporal"
                v-model="form.cond_corporal"
                class="mb-3"
                required
                :disabled="verDetalles ? true : false"
              >
                <b-form-select-option value="1">Muy Delgado</b-form-select-option>
                <b-form-select-option value="2">Delgado</b-form-select-option>
                <b-form-select-option value="3">Peso Ideal</b-form-select-option>
                <b-form-select-option value="4">Sobrepeso</b-form-select-option>
                <b-form-select-option value="5">Obeso</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-anamnesis"
              label="Anamnesis"
              label-for="anamnesis"
            >
              <b-form-input
                id="anamnesis"
                v-model="form.anamnesis"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              id="group-comportamiento"
              label="Comportamiento"
              label-for="comportamiento"
            >
              <b-form-input
                id="comportamiento"
                v-model="form.comportamiento"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              id="group-tratamiento"
              label="Tratamiento"
              label-for="tratamiento"
            >
              <b-form-textarea
                id="tratamiento"
                v-model="form.tratamiento"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              id="group-observaciones"
              label="Observaciones"
              label-for="observaciones"
            >
              <b-form-textarea
                id="observaciones"
                v-model="form.observaciones"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              id="group-diagnostico"
              label="Diagnostico"
              label-for="diagnostico"
            >
              <b-form-textarea
                id="diagnostico"
                v-model="form.diagnostico"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12" md="6">
            <b-form-group
              id="group-firma_persona"
              label="Firma" 
              label-for="firma_persona"
            >
            <b-button @click="cambiarColorFirma">color</b-button>
              <vueSignature ref="firma_persona" :sigOption="optionPad"></vueSignature>
            </b-form-group>
          </b-col> -->
        </b-form-row>
      </b-card>

      <br />

      <b-card
        bg-variant="light"
        header="Procedimientos"
        header-bg-variant="secondary"
        header-text-variant="white"
        align="left"
        v-if="this.atencionVeterinariaSeleccionada.id"
      >
        <template #header>
          <b-row>
            <b-col class="text-left" cols="12" md="4" order="1">
              <div class="mt-1">Procedimientos</div>
            </b-col>
            <b-col cols="12" md="4" class="text-right" order="2" v-if="!verDetalles">

               <b-form-input
                id="nombre_procedimiento"
                v-model="procedimiento.nombre_procedimiento"
                list="procedimiento"
                @input="cargarTipoProcedimiento"
                @blur="seleccionarIdTipoProcedimiento"
              ></b-form-input>

              <datalist id="procedimiento">
                <option v-for="(tipo, tipoIndex) in tiposProcedimientosServicio" :key="tipoIndex">{{ tipo.descripcion }}</option>
              </datalist>
            </b-col>
            <b-col class="text-right" cols="12" md="4" order="5" order-md="3" v-if="!verDetalles">
              <b-button variant="link" size="sm" class="color-yellow" @click="abrirModalAutorizacion">
                <b-icon icon="plus-circle"></b-icon>
                Adicionar
              </b-button>
            </b-col>

          </b-row>
        </template>

        <b-row v-if="procedimientosServicio.length > 0">
          <b-col>
            <b-table
              id="atencionesVeterinarias"
              striped
              responsive
              small
              bordered
              :items="procedimientosServicio"
              :fields="camposTabla"
              :per-page="perPage"
              :current-page="currentPage"
              :tbody-tr-class="rowClass"
              select-mode="single"
              ref="selectableTable"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(seleccionar)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
              <!-- <template #cell(accion)="row">
                  <b-button 
                    size="sm" 
                    v-if="row.item.id != '2'" 
                    class="mr-1 text-center" 
                    block 
                    @click="borrarProcedimiento(row.item, false)"
                  >
                     <b-icon icon="x-circle" variant="danger"></b-icon>
                      borrar
                  </b-button>
              </template> -->
            </b-table>
          </b-col>
        </b-row>
        <b-row v-if="procedimientosServicio.length > 0">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="consultaLicencias"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-card>

      <br /><br />

      <b-modal
        id="modalAutorizacion"
        v-model="mostrarModalAutorizacion"
        hide-footer
        size="lg"
        @hide="cerrarModalProcedimientos"
      >
        <template #modal-title>Detalles Procedimiento</template>
        <div class="d-block p-3">
          <b-row>

            <b-col cols=12 v-if="procedimiento.id_procedimiento == '0'">
                <b-form-group label="Motivo de Eutanasia" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="procedimiento.autorizacionProcedimiento"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2"
                  :disabled="verDetalles ? true : false"
                >
                  <b-form-checkbox value="causa_sufrimiento">Causa sufrimiento</b-form-checkbox>
                  <b-form-checkbox value="incapacidad_grave">Incapacidad grave</b-form-checkbox>
                  <b-form-checkbox value="vejez_extrema">Vejez extrema</b-form-checkbox>
                  <b-form-checkbox value="amenaza_inminente">Amenaza inminente</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>

            <b-col cols=12>
              <b-form-group
              id="group-observacionesProcedimiento"
              label="Observaciones"
              label-for="observacionesProcedimiento"
            >
              <b-form-textarea
                id="observacionesProcedimiento"
                v-model="procedimiento.observaciones"
                :disabled="verDetalles ? true : false"
              ></b-form-textarea>
            </b-form-group>
            </b-col>

            <b-col cols=12 md="6">
              <div class="fotoResultado">
                <img :src="verImagenMascota" alt="foto resultado" />
              </div>
            </b-col>
            
            <b-col cols="12" md="6" v-if="!verDetalles">
              <b-form-group
                id="group-foto_resultado"
                label="Foto Resultado"
                label-for="foto_resultado"
              >
                <b-form-file
                  id="foto_resultado"
                  :state="Boolean(procedimiento.foto_resultado)"
                  placeholder="Foto resultado"
                  browseText="Buscar"
                  accept=".jpg, .JPEG, .JPG"
                  @change="imagenFotoResultado"
                  v-if="!verDetalles"
                ></b-form-file>

              </b-form-group>
            </b-col>

            <b-col class="text-right" v-if="!procedimiento.id && !verDetalles">
              <b-button @click="adicionarProcedimiento" align-self="end"> Guardar </b-button>
            </b-col>

            <b-col class="text-right" v-if="procedimiento.id && !verDetalles">
              <b-button @click="modificarProcedimiento" align-self="end"> Guardar </b-button>
            </b-col>
            
          </b-row>
        </div>
      </b-modal>

      <div class="text-right">
        <b-button class="mr-3" type="submit" v-if="!editar"> Guardar </b-button>
        <b-button class="mr-3" type="submit" v-if="editar"> Guardar </b-button>

        <b-button type="reset"> Cancelar </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { encontrarError } from "../../../contantesErrores";
import ImagenMascota from "../../../assets/imgCabeceras/fotoAnimalesDefault.jpg";
// import vueSignature from "vue-signature";

export default {
  name: "FormularioAgregarAnimal",
  components: {
    // vueSignature,
  },
  props:['vistaVeterinario'],
  data() {
    return {
      editar: false,
      cargadaFoto: false,
      cargadoCarne: false,
      cargadoHC: false,
      verDetalles: false,
      idAtencionVeterinaria: null,
      mostrarModalAutorizacion: false,
      usuario: {},
      cargadoFotoResultado: false,
      optionPad: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      form: {
        id: null,
        id_veterinario: null,
        nombre_persona_cita: null,
        firma_persona: null,
        fecha: null,
        hora: null,
        id_animal: null,
        nombre_animal: null,
        anamnesis: null,
        cond_corporal: null,
        comportamiento: null,
        estado_rep: null,
        mucosas: null,
        tllc: null,
        fr_cardiaca: null,
        fr_respiratoria: null,
        diagnostico: null,
        tratamiento: null,
        observaciones: null,
      },
      procedimiento: {
        id: null,
        id_procedimiento: null,
        id_atencion_vet: null,
        observaciones: null,
        autorizacionProcedimiento: [],
        nombre_procedimiento: null,
        foto_resultado: null
      },
      camposTabla: [
        "nombre_procedimiento",
        "observaciones",
        // {
        //   key: "accion",
        //   label: "Acción"
        // }
      ],
      perPage: 10,
      currentPage: 1,
    };
  },
  async mounted() {
    if (localStorage.getItem("usuario")) {
      try {
        this.usuario = JSON.parse(localStorage.getItem("usuario"));
        this.form.id_veterinario = this.usuario.id_usuario;
      } catch (e) {
        localStorage.removeItem("usuario");
      }
    }

    if(!this.vistaVeterinario){
      this.verDetalles = true
    }else{
      this.verDetalles = false
    }

    if(this.atencionVeterinariaSeleccionada.id){
      this.mapearDatosAtencionVeterinaria()
      this.procedimiento.id_atencion_vet = this.atencionVeterinariaSeleccionada.id
      this.editar=true
      try {
        await this.cargarProcedimientosByIdAtencionVet(this.atencionVeterinariaSeleccionada.id);
      } catch (e) {
        encontrarError(this, e.data);
      }
    }else if(this.animalCargado[0].id){ 
      this.mapearDatosAnimal();
    }

    
  },
  computed: {
    ...mapState("animales", [
      "procedimientosServicio", 
      "animalSeleccionado", 
      "animalCargado", 
      "resultCreateAtencionVeterinaria",
      "atencionVeterinariaSeleccionada",
      "resultCreateProcedimiento",
      "tiposProcedimientosServicio",
      "autorizacionProcedimientoCargado"
    ]),
    rows() {
      return this.procedimientosServicio.length;
    },
    verImagenMascota() {
      let imagen = ImagenMascota;
      if (this.procedimiento.foto_resultado) {
        imagen = `data:image/jpg;base64,${this.procedimiento.foto_resultado}`;
      }
      return imagen;
    },
  },
  methods: {
    ...mapActions("animales", [
      "cargarProcedimientosByIdAtencionVet",
      "crearAtencionVeterinaria", 
      "editarAtencionVeterinaria",
      "crearProcedimiento",
      "editarProcedimiento",
      "resetAtencionVeterinaria",
      "crearAutorizacionProcedimiento",
      "editarAutorizacionProcedimiento",
      "cargarTiposProcedimientoByDescription",
      "cargarAutorizacionProcedimientoById"
    ]),

    rowClass(item, type) {
      if (!item || type !== "row") return;
      return "rowspace";
    },

    async onRowSelected(items) {
      this.procedimiento ={ ...items[0]};
      if(items[0] && items[0].id_procedimiento == 0){
        await this.cargarAutorizacionProcedimientoById(items[0].id)
        this.procedimiento.autorizacionProcedimiento = [];
        let autorizaciones = this.autorizacionProcedimientoCargado[0]

        for (const procedimiento in autorizaciones) {
          if(autorizaciones[procedimiento] == "1"){
            this.procedimiento.autorizacionProcedimiento.push(procedimiento)
          }
        }
      }
      this.mostrarModalAutorizacion = true
    },

    mapearDatosAtencionVeterinaria() {
      this.form = this.atencionVeterinariaSeleccionada
    },

    mapearDatosAnimal() {
      this.form.id_animal = this.animalCargado[0].id;
      this.form.nombre_animal = this.animalCargado[0].nombre;
    },

    modalEnviar(event) {
      event.preventDefault();
      if(this.form.fecha != null && this.form.hora != null){
        this.$bvModal
          .msgBoxConfirm("Se van a guardar los datos, ¿Desea continuar?.", {
            title: "Por favor confirme",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Continuar",
            cancelTitle: "Cancelar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.enviarFormulario();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        this.modalMensaje("Los campos fecha y hora deben estar diligenciados")
      }
    },

    async enviarFormulario() {
      // let firmaPersona = this.$refs.firma_persona.save();
      // let baseFirmaPersona = firmaPersona.split("base64,");
      // this.form.firma_persona = baseFirmaPersona[1];

      console.log("formulario", this.form);

      let datosGuardar = {
          atencionveterinaria: this.form
      }

      try {
        if (this.editar) {
          await this.editarAtencionVeterinaria(datosGuardar);
        } else {
          await this.crearAtencionVeterinaria(datosGuardar);
        }
        if(this.resultCreateAtencionVeterinaria.data.status==200){
          this.modalOk();
        }
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    modalOk() {
      this.$bvModal
        .msgBoxOk("Los Datos fueron guardados correctamente.", {
          title: "Confirmación",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
          this.cancelarModalAtencion();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    abrirModalAutorizacion(){
      if(this.procedimiento.id_procedimiento!=null){
        this.mostrarModalAutorizacion = true
      }else{
        this.modalMensaje('Por favor seleccione un procedimiento')
      }
    },

    async adicionarProcedimiento(){
      console.log('procedimiento', this.procedimiento)
      if(this.procedimiento.id_procedimiento != null){

        let datosCrear = {
          atencionprocedimiento: this.procedimiento
        }

        try{
          await this.crearProcedimiento(datosCrear)
          if(this.resultCreateProcedimiento.data.status==200){
            if(this.procedimiento.id_procedimiento == '0'){
              let datosAutorizacion = {
                autorizacioneutanasia: {
                  id: this.resultCreateProcedimiento.data.data.id,
                  causa_sufrimiento: this.procedimiento.autorizacionProcedimiento.indexOf('causa_sufrimiento') != -1?1:0,
                  incapacidad_grave: this.procedimiento.autorizacionProcedimiento.indexOf('incapacidad_grave') != -1?1:0,
                  vejez_extrema: this.procedimiento.autorizacionProcedimiento.indexOf('vejez_extrema') != -1?1:0,
                  amenaza_inminente: this.procedimiento.autorizacionProcedimiento.indexOf('amenaza_inminente') != -1?1:0
                }
              } 
              await this.crearAutorizacionProcedimiento(datosAutorizacion)
            }
              await this.cargarProcedimientosByIdAtencionVet(this.atencionVeterinariaSeleccionada.id);
              this.cerrarModalProcedimientos()
          }
        }catch(e){
          encontrarError(this, e.data);
        }
      }
    },

    async modificarProcedimiento(){
      console.log('procedimiento', this.procedimiento)

      let datosCrear = {
        atencionprocedimiento: this.procedimiento
      }

      try{
        await this.editarProcedimiento(datosCrear)
        if(this.resultCreateProcedimiento.data.status==200){
          if(this.procedimiento.id_procedimiento == '0'){
            let datosAutorizacion = {
              autorizacioneutanasia: {
                id: this.procedimiento.id,
                causa_sufrimiento: this.procedimiento.autorizacionProcedimiento.indexOf('causa_sufrimiento') != -1?1:0,
                incapacidad_grave: this.procedimiento.autorizacionProcedimiento.indexOf('incapacidad_grave') != -1?1:0,
                vejez_extrema: this.procedimiento.autorizacionProcedimiento.indexOf('vejez_extrema') != -1?1:0,
                amenaza_inminente: this.procedimiento.autorizacionProcedimiento.indexOf('amenaza_inminente') != -1?1:0
              }
            } 
            await this.editarAutorizacionProcedimiento(datosAutorizacion)
          }
            await this.cargarProcedimientosByIdAtencionVet(this.atencionVeterinariaSeleccionada.id);
            this.cerrarModalProcedimientos()
        }
      }catch(e){
        encontrarError(this, e.data);
      }
    },

    cancelarModalAtencion() {
      console.log('boton')
      this.$emit("resetearAtencion");
    },

    modalMensaje(mensaje) {
      this.$bvModal
        .msgBoxOk(mensaje, {
          title: "Error",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async cargarTipoProcedimiento(){
      let dato = 'a';
      if(this.procedimiento.nombre_procedimiento!=null && this.procedimiento.nombre_procedimiento!=''){
        dato = this.procedimiento.nombre_procedimiento
      }
      try{
        await this.cargarTiposProcedimientoByDescription(dato)
        console.log('tipos procedimientos' , this.tiposProcedimientosServicio)
      }catch(e){
        encontrarError(this, e.data);
      }
    },

    seleccionarIdTipoProcedimiento(){
      this.procedimiento.id_procedimiento = this.tiposProcedimientosServicio[0].id
    },

    limpianProcedimientos(){
        this.procedimiento.id=null;
        this.procedimiento.id_procedimiento=null;
        this.procedimiento.observaciones=null;
        this.procedimiento.autorizacionProcedimiento=[];
        this.procedimiento.nombre_procedimiento=null;
        this.procedimiento.foto_resultado=null;
    },

    cerrarModalProcedimientos(){
      this.mostrarModalAutorizacion = false
      this.limpianProcedimientos();
    },

    cambiarColorFirma(){
      console.log('aqui color')
      // this.$refs.firma_persona.clear()
    },

    imagenFotoResultado(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo);
    },

    crearBase64(file) {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let archivo = e.target.result;
          let base = archivo.split("base64,");
          let imagen = base[1];

         this.procedimiento.foto_resultado = imagen
        };

        reader.readAsDataURL(file);
      } else {
        this.procedimiento.foto_resultado = null
      }
    },
  },
};
</script>

<style scoped>
  .fotoResultado {
    overflow: hidden;
    height: 210px;
    position: relative;
    text-align: center;
  }
  .fotoResultado img {
    height: 100%;
  }
</style>